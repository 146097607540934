import React from 'react'
import DatePicker from "react-multi-date-picker";
import {DateObject} from "react-multi-date-picker";
import { useState, useRef } from "react";
import StartEndDates from "./StartEndDates";
import gregorian_it from "./Utils/gregorian_it";
import { useQuery, useQueryClient, } from '@tanstack/react-query';

export default function Calendar() {
    const [value, setValue] = useState();
    const [savedValue, setSavedValue] = useState();
    const datePickerRef = useRef();
    const [price, setPrice] = useState(null);
    /* const queryClient = useQueryClient(); */
    const currentHouseId = 1;

    const fetchDates = async ({queryKey}) => {
        const today = new DateObject(); 
        const todayString = today.format(); // anno/mese/giorno
        const lastDate = today.add(2, 'year');
        const lastDateString = lastDate.format(); // anno/mese/giorno
        /* const response = await fetch(`https://friland-app-backend.visup.me/v1/days/vacancy/${queryKey[0]}/${todayString}/${lastDateString}`);
        const data = await response.json();
        return data; */
        const response = '[{"day_id":"36","day_date":"10/6/2023","vacancy_id":"4","vacancy_is_available":false},{"day_id":"1","day_date":"2/1/2023","vacancy_id":"3","vacancy_is_available":false}]';
        const data = JSON.parse(response);
        return data;
    }
    const fetchPrice = async ({queryKey}) => {
        /* const response = await fetch(`https://friland-app-backend.visup.me/v1/booking/price/${currentHouseId}/${savedValue[0]}/${savedValue[1]}/false`);
        const data = await response.json();
        return data; */
        const response = '{"totalPrice":720,"days":[{"dayId":"1","dayDate":"2/1/2023","dayOfTheWeek":"Martedì","priceId":"1","priceEur":185},{"dayId":"2","dayDate":"2/2/2023","dayOfTheWeek":"Mercoledì","priceId":"2","priceEur":185},{"dayId":"3","dayDate":"2/3/2023","dayOfTheWeek":"Giovedì","priceId":"3","priceEur":185},{"dayId":"4","dayDate":"2/4/2023","dayOfTheWeek":"Venerdì","priceId":"4","priceEur":165}],"discount":60,"totalPriceDiscounted":660,"dog":0}';
        const data = JSON.parse(response);
        return data;
    }

    const datesAvailability = useQuery(['dates', currentHouseId], fetchDates);
    const selectedDatesPrice = useQuery(['price'], fetchPrice);

    const handleSave = () => {
        setSavedValue(value);
        datePickerRef.current.closeCalendar(); // se premo salva chiudo il calendario
        setValue(value);
        const {totalPrice, days, discount, totalPriceDiscounted} =  selectedDatesPrice.data;
        setPrice({totalPrice, nights: days.length, discount, totalPriceDiscounted });
    }
    
    const handleClose = () => { 
        setValue(savedValue);
    }
  
    const handleChange = (range) => {
        const selectedUnavailableDates = datesAvailability.data.some(d => {
            if(d.vacancy_is_available === false) { // se la data non è disponibile controllo che non sia nel range delle date selezionate
                const date = stringToDateObject(d.day_date); // data che potrebbe essere prenotata
                const selectedUnavailableDate = date.format() === range[0]?.format() || date.format() === range[1]?.format(); // ho selezionato esattamente una data non disponibile
                const selectedUnavailableDateRange = (date.unix) > (range[0]?.unix) && (date.unix) < (range[1]?.unix); // ho selezionato un range di date che include una data non disponibile
                return selectedUnavailableDate || selectedUnavailableDateRange;
            }
            return false;
        });
        if(selectedUnavailableDates) return false;
        const {totalPrice, days, discount, totalPriceDiscounted} =  selectedDatesPrice.data;
        setPrice({totalPrice, nights: days.length, discount, totalPriceDiscounted });
        setValue(range);
    }
      
      const daysMapping = ({date}) => {
          let className;
          // con zero al posto di -10 non funziona. Controlla che la data non sia precedente a oggi o una di quelle non disponibili
          if(date.unix - (new DateObject()).unix < -10 ||  datesAvailability.data.find(d => d.day_date === date.format('D/M/YYYY'))?.vacancy_is_available === false) { 
              className = 'text-gray-300 line-through pointer-events-none';
          }
          if(className) return {className};
      }
  
      // funzione per convertire una stringa in un DateObject 
      const stringToDateObject = (string) => {
          const [day, month, year] = string.split('/');
          return new DateObject(`${year}-${month}-${day}`);
      }
  
      const handleBooking = () => {
          if(!savedValue) return false;
          alert('prenotazione')
      }

    return (
        <div className="w-full h-[500vh] mobile">
            <DatePicker
                className="bg-[#fffbf0] !shadow-none !rounded-none"
                ref={datePickerRef}
                fixRelativePosition={true}
                // traduzione italiano
                locale={gregorian_it}
                // mostro i giorni precedenti e successivi
                showOtherDays={false}
                // rimuovo l'highlight del giorno corrente
                highlightToday={false}
                // inizio la settimana con lunedì
                weekStartDayIndex={1}
                //mostro due mesi
                numberOfMonths={2}
                // abilito la selezione di un range di date
                range
                value={value}
                onChange={handleChange}
                onClose={handleClose}
                // renderizzo il componente custom
                render={<StartEndDates savedValue={value} price={price} handleBooking={handleBooking}/>}
                // mapping dei giorni disponibili e non disponibili
                mapDays={daysMapping}
            >
                <div className="bg-[#fffbf0] w-full py-4 px-3 flex justify-between items-center">
                    {
                        price && value ?
                        <>
                            <div className='absolute -top-[4.2rem] left-0 w-full bg-[#fffbf0] pt-3 text-2xl font-bold text-left px-3'>
                                {price.nights} Notti
                                <p className='!text-[#666666] text-base font-normal'>{value[0] && value[0]?.format('D MMM YYYY')} - {value[1] && value[1]?.format('D MMM YYYY')}</p>
                            </div>
                            <div className='flex flex-col justify-between text-left'>
                                <div className='text-base'>totale: <span className='font-bold text-xl'>{price.totalPriceDiscounted}</span></div>
                                <div className='text-sm'>{value[0] && value[0].format('D MMM YYYY')} - {value[1] && value[1].format('D MMM YYYY')}</div>
                            </div>
                        </>
                        :
                            <p>seleziona le date</p>
                    }
                    <button className="text-white text-lg bg-blue rounded-lg py-3 px-10" onClick={handleSave}>Salva</button>
                </div>
            </DatePicker>
        </div>
    );
}
