import Calendar from './Calendar';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

function App() {

  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <Calendar />
    </QueryClientProvider>
  )
}

export default App;
