import React from 'react'

export default function StartEndDates({ openCalendar, savedValue, price, handleBooking }) {
    let from = savedValue && savedValue[0] ? savedValue[0].format('D MMM YYYY') : "";
    let to = savedValue && savedValue[1] ? savedValue[1].format('D MMM YYYY') : "";
    
    return (
        <div className="bg-[#fffbf0] w-full py-4 px-3 fixed bottom-0 flex justify-between items-center"  onClick={openCalendar}>
            {
                price ?
                    <div className='flex flex-col justify-between'>
                        <div className='text-base'>totale: <span className='font-bold text-xl'>{price.totalPriceDiscounted}</span></div>
                        <div className='text-sm'>{from} - {to}</div>
                    </div>
                :
                    <p>seleziona le date</p>

            }
            <button className="text-white text-lg bg-blue rounded-lg py-3 px-10" onClick={handleBooking}>Prenota</button>
        </div>
    )
}